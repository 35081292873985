<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMLegalHomeSection />
    </template>
    <template v-else>
      <EvoLegalHomeSection />
    </template>
  </div>
</template>
<script>
import EvoLegalHomeSection from '@/components/legal_sections/Home.vue';
import EvoMLegalHomeSection from '@/mobile/components/legal_sections/MHome.vue';
export default {
  name: 'EvoLegalHomeView',
  components: {
    EvoLegalHomeSection,
    EvoMLegalHomeSection
  }
};
</script>
